import cookie from "@utils/cookie";

import { dpApiV2 } from "./apiV2";

export type User = {
  name: string;
  email: string;
  id: number;
  is_superuser: boolean;
  is_charity_admin: boolean;
  is_retailer_admin: boolean;
  is_end_user: boolean;
}

type CreateUserRequest = {
  username: string;
  email: string;
  password: string;
  first_name: string;
}

type CreateUserResponse = {
  email: string;
  username: string;
  id: number;
}


const extendedApi = dpApiV2.injectEndpoints({
  endpoints: builder => ({
    createEndUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query: body => ({
        method: "POST",
        url: "auth/users/",
        body
      }),
    }),
    resendActivationEmail: builder.mutation<void, string>({
      query: email => ({
        method: "POST",
        url: "auth/users/resend_activation/",
        body: { email }
      }),
    }),
    createJWT: builder.mutation<{ refresh: string; access: string; }, { email: string; password: string; }>({
      query: body => ({
        method: "POST",
        url: "auth/jwt/create/",
        responseHandler: async response => {
          if (response.ok) {
            const responseJson = await response.json();
            cookie.set("jwt", responseJson.access, 1);
            cookie.set("jwt-refresh", responseJson.refresh, 72);
            return responseJson;
          }
          // Just return the error data
          return await response.json();
        },
        body
      }),
      invalidatesTags: [ "User" ],
    }),
    getLoggedInUser: builder.query<User, void>({
      query: () => ({
        url: "auth/users/me/",
        responseHandler: async response => {
          const resJson = await response.json();
          return resJson;
        },
      }),
      providesTags: [ "User" ]
    }),
    updatePassword: builder.mutation<{
      success: boolean
    }, {
      new_password: string;
      current_password: string;
    }>({
      query: body => ({
        method: "POST",
        url: "auth/users/set_password/",
        body
      }),
      invalidatesTags: [ "User" ]
    }),
    updateEmail: builder.mutation<{
      success: boolean
    }, {
      new_email: string;
      current_password: string;
    }>({
      query: body => ({
        method: "POST",
        url: "auth/users/set_email/",
        body
      }),
      invalidatesTags: [ "User" ]
    }),
    resetPassword: builder.mutation<void, { email: string; }>({
      query: body => ({
        method: "POST",
        url: "auth/users/reset_password/",
        body
      })
    }),
    confirmResetPassword: builder.mutation<void, { uid: string; token: string; new_password: string; }>({
      query: body => ({
        method: "POST",
        url: "auth/users/reset_password_confirm/",
        body
      })
    }),
  })
});

export const {
  useCreateEndUserMutation,
  useResendActivationEmailMutation,
  useCreateJWTMutation,
  useGetLoggedInUserQuery,
  useUpdatePasswordMutation,
  useUpdateEmailMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation
} = extendedApi;
